<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Using the table of thermodynamic data provided below, answer each of the questions listed
        below for the following reaction:
      </p>

      <p class="pl-10 mb-4">
        <chemical-latex content="3 A(g) + B(s) -> 2 C(g) + D(g)" />
      </p>

      <v-simple-table class="mb-5" style="width: 400px" dense>
        <thead>
          <tr>
            <th style="vertical-align: middle; font-size: 15px">
              <stemble-latex content="$\text{Species}$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <stemble-latex content="$\text{S}^\circ\text{( J K}^{-1}\text{mol}^{-1})$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <stemble-latex content="$\Delta\text{H}_{\text{f}}^\circ\text{ (kJ/mol)}$" />
            </th>
          </tr>
        </thead>
        <!-- Body of the table -->
        <tbody>
          <tr>
            <th style="vertical-align: middle; font-size: 13px">
              <stemble-latex content="$\text{B(g)}$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 13px">
              <number-value :value="SBgas" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 13px">
              <number-value :value="HBgas" />
            </th>
          </tr>
          <tr>
            <th style="vertical-align: middle; font-size: 13px">
              <stemble-latex content="$\text{A(g)}$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 13px">
              <number-value :value="SAgas" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 13px">
              <number-value :value="HAgas" />
            </th>
          </tr>
          <tr>
            <th style="vertical-align: middle; font-size: 13px">
              <stemble-latex content="$\text{B(s)}$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 13px">
              <number-value :value="SBsolid" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 13px">
              <number-value :value="HBsolid" />
            </th>
          </tr>
          <tr>
            <th style="vertical-align: middle; font-size: 13px">
              <stemble-latex content="$\text{A(s)}$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 13px">
              <number-value :value="SAsolid" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 13px">
              <number-value :value="HAsolid" />
            </th>
          </tr>
          <tr>
            <th style="vertical-align: middle; font-size: 13px">
              <stemble-latex content="$\text{D(g)}$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 13px">
              <number-value :value="SDgas" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 13px">
              <number-value :value="HDgas" />
            </th>
          </tr>
          <tr>
            <th style="vertical-align: middle; font-size: 13px">
              <stemble-latex content="$\text{C(g)}$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 13px">
              <number-value :value="SCgas" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 13px">
              <number-value :value="HCgas" />
            </th>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2">
        a) Determine
        <stemble-latex content="$\Delta\text{S}^\circ_{\text{rxn}}$" /> for the reaction in
        <stemble-latex content="$\text{J K}^{-1}\text{mol}^{-1}\text{?}$" />
      </p>

      <calculation-input
        v-model="inputs.deltaS"
        class="mb-6"
        prepend-text="$\Delta\text{S}^\circ_{\text{rxn}}:$"
        append-text="$\text{J K}^{-1}\text{mol}^{-1}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) What is the value of
        <stemble-latex content="$\Delta\text{H}^\circ_{\text{rxn}}$" />
        for the reaction in
        <stemble-latex content="$\text{kJ/mol.}$" />
      </p>

      <calculation-input
        v-model="inputs.deltaH"
        class="mb-6"
        prepend-text="$\Delta\text{H}^\circ_{\text{rxn}}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) Based on your answers for parts a) and b), determine the standard Gibb's Free energy of
        the reaction in
        <stemble-latex content="$\text{kJ/mol}$" />
        at
        <stemble-latex content="$\text{298 K.}$" />
      </p>

      <calculation-input
        v-model="inputs.freeEnergy"
        class="mb-6"
        prepend-text="$\Delta\text{G}^\circ_{\text{rxn}}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-0">
        d) Under standard conditions, in which direction would the reaction be spontaneous at
        <stemble-latex content="$298\,\text{K?}$" />
      </p>

      <v-radio-group v-model="inputs.spontDirection" class="pl-8 pt-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block pt-0"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question314', // Was 290
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        deltaS: null,
        deltaH: null,
        freeEnergy: null,
        spontDirection: null,
      },
      options: [
        {expression: '$\\text{Forward direction}$', value: 'forward'},
        {expression: '$\\text{Reverse direction}$', value: 'reverse'},
        {expression: '$\\text{Neither direction}$', value: 'neither'},
        {expression: '$\\text{Both directions}$', value: 'both'},
      ],
    };
  },
  computed: {
    SAgas() {
      return this.taskState.getValueBySymbol('SAgas').content;
    },
    SBgas() {
      return this.taskState.getValueBySymbol('SBgas').content;
    },
    SCgas() {
      return this.taskState.getValueBySymbol('SCgas').content;
    },
    SDgas() {
      return this.taskState.getValueBySymbol('SDgas').content;
    },
    HAgas() {
      return this.taskState.getValueBySymbol('HAgas').content;
    },
    HBgas() {
      return this.taskState.getValueBySymbol('HBgas').content;
    },
    HCgas() {
      return this.taskState.getValueBySymbol('HCgas').content;
    },
    HDgas() {
      return this.taskState.getValueBySymbol('HDgas').content;
    },
    SAsolid() {
      return this.taskState.getValueBySymbol('SAsolid').content;
    },
    SBsolid() {
      return this.taskState.getValueBySymbol('SBsolid').content;
    },
    HAsolid() {
      return this.taskState.getValueBySymbol('HAsolid').content;
    },
    HBsolid() {
      return this.taskState.getValueBySymbol('HBsolid').content;
    },
  },
};
</script>
